.nav-button {
  height: 100%;
  display: flex;
  flex: 1;
  margin-top: 5px;
  cursor: pointer;

  p {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5px;
  }
}