.vehicle_container {
  width: calc(100vw - 40px);
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: 1024px) {
    width: calc(80vw - 80px);
    margin-left: 40px;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 60vh;
    min-height: 550px;
    margin-bottom: 40px;
  }

  &.zoom {
    transition: 0.2s;
    border: 8px solid white;

    &:hover {
      transform: scale(
        1.023
      ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
      transition: 0.2s;
      border: 8px solid #004c9bee;

      .rotate_button {
        animation: shadow-pulse 1s infinite;
      }
    }

    .rotate_button {
      position: absolute;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      z-index: 1000;
      height: 50px;
      width: 50px;
      z-index: 999;
      border-radius: 25px;
      cursor: pointer;
    }
  }

  .header_text_container {
    margin-top: 50px;
    z-index: 99;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 80px;
      margin-bottom: 50px;
    }

    @media screen and (min-width: 1024px) {
      position: absolute;
      bottom: 30px;
      left: 30px;
    }

    .car_header {
      text-align: center;
      font-weight: bolder;
      font-size: 25px;
      padding-left: 20px;
      padding-right: 20px;
      width: calc(100% - 40px);

      @media only screen and (max-width: 330px) {
        font-size: 23px;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
        text-align: center;
        font-weight: bolder;
        font-size: 48px;
      }

      @media screen and (min-width: 1024px) {
        padding-left: 0;
        width: 700px;
        font-size: 30px;
        text-align: left;
      }
    }

    .car_sub_text {
      text-align: center;
      margin-top: 10px;
      padding-left: 30px;
      padding-right: 30px;
      width: calc(100% - 60px);

      @media only screen and (max-width: 330px) {
        font-size: 13px;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        font-size: 25px;
      }

      @media screen and (min-width: 1024px) {
        padding-left: 0;
        width: 700px;
        font-size: 14px;
        text-align: left;
        margin-top: 10px;
      }
    }

    &.text-SlingShot {
      padding-left: 0px;
      padding-right: 0px;
      width: calc(100% - 0px);

      .car_header {
        padding-left: 0px;
        padding-right: 0px;
        width: calc(100% - 0px);
      }
    }

    &.text-Ranger {
      padding-left: 20px;
      padding-right: 20px;
      width: calc(100% - 40px);
    }

    &.text-Snowmobile {
      .car_header {
        width: 100%;
        padding-left: 0px;
      }

      .car_sub_text {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
      }
    }

    &.text-FTR {
      .car_header {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
      }

      .car_sub_text {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
      }
    }

    &.text-Boat {
      .car_header {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
      }

      .car_sub_text {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
      }
    }
  }

  .poster {
    height: 50%;
    width: 100%;
    object-fit: contain;
    width: 90%;
    margin-top: 30px;
    margin-bottom: 30px;

    @media screen and (min-width: 1024px) {
      height: 80%;
      margin-top: 40px;
      margin-bottom: 0px;
      pointer-events: none;
    }

    &.poster-bobber {
      @media screen and (min-width: 1024px) {
        transform: scale(0.8);
      }
    }

    &.poster-boat {
      @media screen and (min-width: 1024px) {
        margin-bottom: 100px;
        transform: scale(0.7);
      }
    }

    &.poster-fourwheeler {
      transform: scale(0.9);

      @media screen and (min-width: 1024px) {
        transform: scale(0.8);
        margin-bottom: -20px !important;
      }
    }

    &.poster-indian_ftr {
      transform: scale(1.2);

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 30px;
      }

      @media screen and (min-width: 1024px) {
        margin-bottom: 60px;
        transform: scale(0.8);
      }
    }

    &.poster-ranger {
      @media screen and (min-width: 1024px) {
        transform: scale(0.8);
        margin-bottom: 30px;
      }
    }

    &.poster-rzr {
      transform: scale(1.1);

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        transform: scale(1.3);
      }

      @media screen and (min-width: 1024px) {
        margin-bottom: -80px;
        transform: scale(0.9);
      }
    }

    &.poster-slingshot {
      @media screen and (min-width: 1024px) {
        transform: scale(0.6);
      }
    }

    &.poster-snowmobile {
      @media screen and (min-width: 1024px) {
        transform: scale(0.75);
      }
    }
  }

  .modelView {
    position: absolute;
    background-color: blue;
    height: 100%;
    width: 100%;
  }

  .cta {
    object-fit: contain;
    margin-bottom: 30px;
    z-index: 1000;
    font-family: "barlowregular";
    color: white;
    background-color: #004c9d;
    padding: 15px 50px;
    font-size: 18px;
    border-radius: 5px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 125px;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      object-fit: contain;
      margin-bottom: 45px;
    }

    @media screen and (min-width: 1024px) {
      position: absolute;
      bottom: 0px;
      right: 25px;
    }

    &.cta--cube {
      padding: 10px 50px;

      img {
        width: 26px;
        height: 29px;
        margin-right: 10px;
      }
    }
  }

  @keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 0 35px rgba(255, 255, 255, 0);
    }
  }
}
