.hero_image_container {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;

  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
  }

  @media screen and (min-width: 1024px) {
    height: auto;
    margin-bottom: 35px;
    padding: 100px 100px 40px 100px;
  }

  .header_text {
    font-family: barlowsemicondensedbold;
    font-weight: 700;
    font-size: 28px;
    color: white;
    text-align: center;
    line-height: 45px;
    margin: 50px auto;
    position: relative;
    z-index: 3;

    @media screen and (min-width: 500px) {
      margin: 100px auto;
      width: 75%;
    }

    @media screen and (min-width: 1024px) {
      margin: 0 auto 150px;
    }
  }

  .sub_text {
    color: white;
    font-family: barlowmedium;
    font-weight: lighter;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    opacity: 0.9;
    display: none;
    position: relative;
    z-index: 3;

    a {
      color: white;
    }

    @media screen and (min-width: 350px) {
      display: block;
    }

    @media screen and (min-width: 1024px) {
      margin-bottom: 50px;
    }

    sup {
      position: relative;
      top: -4px;
      font-size: x-small;
    }
  }

  .directions {
    font-family: barlowmedium;
    font-weight: lighter;
    font-size: 14px;
    line-height: 20px;
    color: white;
    text-align: center;
    opacity: 0.8;
    margin-bottom: 30px;
    position: relative;
    z-index: 3;
  }

  .button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

    @media screen and (min-width: 1024px) {
      position: initial;
      bottom: initial;
      left: initial;
      transform: initial;
    }

    .scrollButton {
      background-color: #004c9d;
      border-radius: 5px;
      padding: 20px 25px 15px 25px;
      cursor: pointer;
      position: relative;
      z-index: 3;
      transition: all 0.25s linear;

      &:hover {
        filter: brightness(120%);
      }

      img {
        width: 26px;
        height: 15px;
      }
    }
  }

  .hero_image {
    object-fit: contain;
    width: 100%;
  }
}