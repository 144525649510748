.app_container_indian {
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    @media screen and (min-width: 1024px) {
        width: 80vw;
        margin: 0 auto;
    }

    .app_header_indian {
        .logo-link {
            .left_img {
                width: 140px;
                margin-top: 4px;
            }
        }
            
        .right_img {
            width: 292px;
            margin-top: 2px;
            height: 60px;
            display: none;

            @media screen and (min-width: 500px) {
                display: block;
            }
        }
    }

    .hero_image_container {
        margin-bottom: 20px;

        .scrollButton {
            background-color: #a62b40;
        }

        .header_text,
        .sub_text,
        .directions {
            opacity: 1.0;
            text-shadow: 1px 1px black;
        }

        .header_text {
            font-family: 'Teko', sans-serif;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 60px;
        }

        .sub_text,
        .directions {
            font-family: Arial, sans-serif;
        }

        .sub_text {

        }
    }

    .vehicle_container {
        &.zoom:hover {
            border: 8px solid #a62b40;
        }

        .car_header {
            font-family: 'Teko', sans-serif;
            font-weight: 500;
            text-transform: uppercase;
        }

        .car_sub_text {
            font-family: Arial, sans-serif;
        }

        .cta {
            background-color: #a62b40;
            font-family: Arial, sans-serif;
        }
    }

    .footer p {
        font-family: Arial, sans-serif;
        line-height: 21px;
    }
}