.app_container {
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    @media screen and (min-width: 1024px) {
        width: 80vw;
        margin: 0 auto;
    }
}