.footer {
  height: 50px;
  background-color: #0b0b0b;
  display: flex;
  align-items: center;

  p {
    color: white;
    margin-left: 25px;
    font-family: "barlowmedium"
  }
}