/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 30, 2019 */



@font-face {
    font-family: 'barlowblack';
    src: url('barlow-black-webfont.woff2') format('woff2'),
         url('barlow-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlowblack_italic';
    src: url('barlow-blackitalic-webfont.woff2') format('woff2'),
         url('barlow-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlowlight';
    src: url('barlow-light-webfont.woff2') format('woff2'),
         url('barlow-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlowmedium';
    src: url('barlow-medium-webfont.woff2') format('woff2'),
         url('barlow-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlowregular';
    src: url('barlow-regular-webfont.woff2') format('woff2'),
         url('barlow-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlowsemibold';
    src: url('barlow-semibold-webfont.woff2') format('woff2'),
         url('barlow-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlowregular';
    src: url('barlow-webfont.woff2') format('woff2'),
         url('barlow-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlowextralight';
    src: url('barlow-webfont.woff2') format('woff2'),
         url('barlow-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'barlowsemicondensedbold';
    src: url('BarlowSemiCondensed-Bold.woff2') format('woff2'),
         url('BarlowSemiCondensed-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


