.app_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    padding: 2px 20px;
    background-color: white;

    @media only screen and (max-device-width: 330px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .logo-link {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .left_img {
            object-fit: contain;
            width: 140px;
            margin-top: 4px;

            @media only screen and (max-device-width: 330px) {
                object-fit: contain;
                width: 120px;
            }
        }
    }

    .right_img {
        object-fit: contain;
        width: 140px;
        margin-top: 6px;

        @media only screen and (max-device-width: 330px) {
            object-fit: contain;
            width: 120px;
        }
    }

    .header_tagline {
        font-family: 'barlowlight';
        font-size: 1.2rem;
        margin-top: 24px;
    }
}