.vehicle-model-page {
    height: 100vh;
    width: 100vw;
    background-color: white;
    padding-top: 0px;
    margin-top: -40px;

    .cta {
        height: 50px;
        object-fit: contain;
        cursor: pointer;
    }

    .btn {
        position: absolute;
        bottom: 100px;
        left: 0;
        right: 0;
        height: 50px;
        background-color: none;
        outline: none;
        border: none;
    }

    .model-viewer {
        width: 100%;
        height: 100%;
        margin-top: 30px;
    }

    .extra {
        margin-top: 65px;
        transform: scale(0.8);
    }

}